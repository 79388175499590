<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.6667 0.666504H1.33333C0.979711 0.666504 0.640573 0.80698 0.390525 1.05703C0.140476 1.30708 0 1.64622 0 1.99984V17.9998C0 18.3535 0.140476 18.6926 0.390525 18.9426C0.640573 19.1927 0.979711 19.3332 1.33333 19.3332H10.6667C11.0203 19.3332 11.3594 19.1927 11.6095 18.9426C11.8595 18.6926 12 18.3535 12 17.9998V1.99984C12 1.64622 11.8595 1.30708 11.6095 1.05703C11.3594 0.80698 11.0203 0.666504 10.6667 0.666504V0.666504ZM1.33333 1.99984H10.6667V13.9998H1.33333V1.99984ZM1.33333 17.9998V15.3332H10.6667V17.9998H1.33333Z" />
  </svg>
</template>
